import React from 'react'
import { documentTitle } from 'utils/document'
import Head from 'next/head'
import { Block, Image, ResponsivePhone, ResponsiveTablet, ResponsiveDesktop, Paragraph, Button } from 'uikit'
import styled from 'styled-components'
import notFoundLg from 'resources/img/404-lg.png'
import notFoundSm from 'resources/img/404-sm.png'

const Root = styled(Block)`
  background: #f4f5f6;
  width: 100%;
  height: 100%
`

const Title = styled(Paragraph)`
  margin-top: 24px;
  margin-bottom: 8px !important;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  
  ${props => props.theme.viewPorts.mobile} {
    font-size: 24px;
  }
`

const SubTitle = styled(Paragraph)`
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

const ButtonContainer = styled(Block)`
  margin-top: 48px;
  
  ${props => props.theme.viewPorts.mobile} {
    margin-top: 40px;
  }
`

const NotFound = () => {
  const imgLg = <Image src={notFoundLg} width={376} height={232} isRem={false}/>
  const imgSm = <Image src={notFoundSm} width={288} height={179} isRem={false}/>

  return (
    <>
      <Head>
        <title>{documentTitle('Страница не найдена')}</title>
      </Head>
      <Root isCenter>
        <Block>
          <ResponsiveDesktop>{imgLg}</ResponsiveDesktop>
          <ResponsiveTablet>{imgLg}</ResponsiveTablet>
          <ResponsivePhone>{imgSm}</ResponsivePhone>

          <Title>Страница не найдена</Title>
          <SubTitle type="gray4">или была изъята за долги</SubTitle>

          <ButtonContainer isCenter>
            <Button uri="/" href="/" type="primary">
              Перейти на главную страницу
            </Button>
          </ButtonContainer>
        </Block>
      </Root>
    </>
  )
}

export default NotFound
